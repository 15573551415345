<template>
  <div style="background:#f4f4f7">
    <Nav ref="navchild"></Nav>
    <div class="banner-width">
      <Banner></Banner>
    </div>
    <div class="mainwidth">
      <Title>
        <template v-slot:one>
          <el-divider content-position="center">精彩展项</el-divider>
        </template>
        <template v-slot:two>
          <span class="sub1">Wonderful exhibition items</span>
        </template>
      </Title>
      <div class="list">
        <div
          class="item"
          v-for="(item,index) in list"
          :key="index"
          @click="gotoJczxDetails(item.id)"
        >
          <img :src="item.pictureVideo" />
          <div class="mar_con_center">＋</div>
          <div class="brief">
            <h4>{{item.theme}}</h4>
            <div class="line">
              <div>作者：{{item.author}}</div>
              <div class="company">单位：{{item.unit}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="page">
        <el-pagination
          background
          :page-size="listPage.pageSize"
          layout="prev, pager, next"
          :current-page="listPage.currentPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :total="listPage.totalCount"
        ></el-pagination>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from "@/views/pc/components/Nav";
import Banner from "@/views/pc/components/Banner";
import Footer from "@/views/pc/components/Footer";
import Title from "@/views/pc/components/Title";
import { jczxList } from "@/api/pc.js";
export default {
  name: "Exibits",
  data() {
    return {
      listPage: {
        // pageSize代表每页显示的数目
        pageSize: 6,
        // currentPage代表当面的页数
        currentPage: 1,
        // totalCount列表的总数
        totalCount: 10
      },
      list: [{}]
    };
  },
  created() {
    this.getJczxList();
  },
    mounted() {
    var arr = this.$route.path.split("/");
    this.$refs.navchild.setNavIndex("/" + arr[1]);
  },
  methods: {
    // pageSize 改变时会触发的事件
    handleSizeChange(val) {
      this.listPage.pageSize = val;
      // 当pageSize发生变化后需重新查询列表
      this.getJczxList();
    },
    // currentPage 改变时会触发
    handleCurrentChange(val) {
      this.listPage.currentPage = val;
      // 当currentPage发生变化后需重新查询列表
      this.getJczxList();
    },
    getJczxList() {
      let params = {
        pageNo: this.listPage.currentPage,
        pageSize: this.listPage.pageSize,
 
      };
      jczxList(params).then(res => {
        this.list = res.data.list;
        this.listPage.totalCount = res.data.total
      });
    },
    gotoJczxDetails(id){
   this.$router.push(`/jczx/${id}`);

    }
  },
  components: { Footer, Nav, Title, Banner }
};
</script>
<style scoped>
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: #fff;
}
</style>
<style scoped lang="scss">
.page {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.sub1 {
  background: none;
  color: #999;
}
.banner-width {
  background: url("~@/assets/pcimg/bg1.jpg") center no-repeat;
}

.mainwidth {
  width: 1200px;
  margin: 0 auto;

  .list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;

    .item {
      margin-top: 20px;
      width: 384px;
      position: relative;
      margin-left: 20px;
      cursor: pointer;
      .mar_con_center {
        width: 73px;
        height: 73px;
        background-color: #00c6ff;
        border-radius: 50%;
        text-align: center;
        line-height: 73px;
        color: #fff;
        position: absolute;
        font-weight: bold;
        left: 50%;
        margin-left: -50px;
        top: 50%;
        margin-top: -80px;
        transition: 0.5s all;
        opacity: 0;
      }
      img {
      width: 100%;
        height: 285px;
        object-fit: cover;
        display: block;
      }
      .brief {
        height: 109px;
        color: #fff;
        background: linear-gradient(90deg, #4452da, #00c7ff);
        h4 {
          padding-left: 20px;
          padding-top: 15px;
          font-size: 24px;
         white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
        padding-right: 20px;
        }
        .line {
          display: flex;

          padding-top: 15px;
          border-bottom: #fff 1px solid;
          margin-left: 20px;
          margin-right: 20px;
          padding-bottom: 5px;
          font-size: 16px;
          color: #fff;
          .company {
            padding-left: 30px;
          }
        }
      }

      &:hover .mar_con_center {
        opacity: 1;
      }
    }
  }
}
</style>