<template>
  <div>
    <!-- 活动周标题 -->
    <top :islist="islist"></top>
    <!-- 模块标题 -->
    <tech-title
      :category1="category1"
      :category2="category2"
      :subtitle="subtitle"
      :iscloud="iscloud"
    ></tech-title>
    <!-- 列表 -->
    <ul class="list">
      <li v-for="item in list" :key="item.id" @click="goDetail(item.id)">
        <div class="img-wrapper">
          <img :src="item.pictureVideo" alt="" />
        </div>
        <div class="title">
          <p>{{ item.theme }}</p>
          <div class="info">
            <span>作者：{{ item.author }}</span>
            <span>单位：{{ item.unit }}</span>
          </div>
        </div>
      </li>
    </ul>
    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <page-footer></page-footer>
  </div>
</template>

<script>
import Top from '@/views/mobile/components/Top.vue';
import TechTitle from './components/TechTitle.vue';
import PageFooter from './components/PageFooter.vue';
import Pagination from './components/Pagination.vue';
import { getExhibits } from '@/api/mobile.js';
export default {
  name: 'MExihibits',
  components: {
    Top,
    TechTitle,
    PageFooter,
    Pagination,
  },
  data() {
    return {
      category1: '精彩',
      category2: '展项',
      subtitle: 'Wonderful exhibition items',
      islist: true,
      iscloud: false,
      total: 10,
      list: [],
      listQuery: {
        page: 1,
        limit: 5,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      let params = {
        pageNo: this.listQuery.page,
        pageSize: this.listQuery.limit,
      };
      getExhibits(params).then((res) => {
        this.total = res.data.total;
        this.list = res.data.list;
      });
    },
    goDetail(id) {
      this.$router.push('/jczx/'+id)
    },
  },
};
</script>

<style scoped lang="scss">
.list {
  padding: 30px;
}
.list li {
  margin-top: 30px;
}
.list li .img-wrapper {
  width: 690px;
  height: 380px;
  overflow: hidden;
  position: relative;
}
// .list li .img-wrapper::after {
//   display: block;
//   content: '';
//   width: 100px;
//   height: 100px;
//   position: absolute;
//   z-index: 2;
//   left: 50%;
//   top: 50%;
//   border-radius: 50%;
//   background: url('../../assets/img/icon-add.png') no-repeat center center;
//   background-size: 20px 20px;
//   background-color: #4ec7ff;
//   transform: translate(-50%, -50%);
// }
.list li img {
  display: block;
  width: 690px;
  height: 380px;
  object-fit: cover;
}
.list .title {
  position: relative;
  color: #fff;
  padding: 24px 120px 40px 40px;
  background: linear-gradient(90deg, #4450e2, #48b9fd);
}
.list .title p {
  font-size: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.list .title .info {
  font-size: 24px;
  display: flex;
  margin-top: 20px;
}
.list .title .info span {
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.list .title::after {
  display: block;
  content: '';
  position: absolute;
  height: 1px;
  background-color: #fff;
  left: 35px;
  right: 120px;
  bottom: 30px;
}
</style>
